import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 检查表列表
export const getExamineList = (params: any) => {
  return request({
    url: "/factory/table/list",
    method: "get",
    params,
  });
};

// 检查表新增
export const postExamineAdd = (data: any) => {
  return request({
    url: "/factory/table",
    method: "post",
    data: data,
  });
};

// 检查表查看
export const getExamineDetail = (id: any) => {
  return request({
    url: `/factory/table/detail/${id}`,
    method: "get",
  });
};

// 检查表修改
export const putExamineEdit = (data: any) => {
  return request({
    url: "/factory/table",
    method: "put",
    data: data,
  });
};
